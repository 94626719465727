import React, { useState, useEffect } from 'react';
import {  Form, Popconfirm, Select, Table, Typography } from 'antd';
import { useDispatch, useSelector } from "react-redux";
import { ApiPutListingRegulationValue} from '../../Services';
import _ from 'lodash';
import { _capitalize } from 'chart.js/helpers';
import { splitStringIntoTwoLines } from '../../Helpers/ValueFormater';
import { ListingRegulationKeysThunk, ListingRegulationValueThunk } from '../../Store/Thunks/ListingRegulationThunk';

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ListingGeneralValueData,
  ...restProps
}) => {
  const micaClassificationValueData = Object.freeze([
    { name: "ART", key: "ART" },
    { name: "EMT", key: "EMT" },
    { name: "OCA", key: "OCA" },
    { name: "MEME", key: "MEME" },
  ]);

  return (
    <td {...restProps}>
    {editing ? ( 
          <>
           <Form.Item name={dataIndex} style={{ margin: 0 }}>
           <Select
            style={{ width: "50%" }}
           // value={text} // Set the selected value
           // onChange={(value) => handleSelectChange(value, record, item.key)}
          >
            {micaClassificationValueData.map((option) => (
              <Select.Option key={option.key} value={option.key}>
                {option.name}
              </Select.Option>
            ))}
          </Select>
          </Form.Item>        
          </>
        ) : (
          children
        )
        }
  </td>
  );
};

export default EditableCell;

export const Regulation = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [editingKey, setEditingKey] = useState('');
  const [isSaveClicked, setIsSaveClicked] = useState(false);
  const [modifiedRows, setModifiedRows] = useState(new Set());
  const [cellToggleState, setCellToggleState] = useState({}); // Track each cell's state individually

  useEffect(() => {
    dispatch(ListingRegulationKeysThunk());
    dispatch(ListingRegulationValueThunk());
  }, []);

  const {
    ListingRegulationKeyData,
    ListingRegulationKeyLoading,
    ListingRegulationValueData,
    ListingRegulationValueLoading,
  } = useSelector((state) => state.ListingRegulationReducer);


  
  const isEditing = (record) => record.key === editingKey;

  const edit = (record) => {     
    form.setFieldsValue({ ...record });
    setEditingKey(record.key);
  };

  const cancel = () => {
    setEditingKey('');
  };

  const dataSource = () => {
    const arr = [];
    ListingRegulationValueData.map((item, index) => {    
      const obj = {};
      ListingRegulationKeyData.map((element) => {
        if (item.listingRegulation) {         
          obj[element.key] = item.listingRegulation[element.key];         
          obj.id = item.listingRegulation.id;
        } else {       
          obj[element.key] = "";         
          obj.id = item.id;
        }
      });
      
      arr.push({
        name: item.assetName,
        ...obj,
        key: index,
      });
    });
      
    setData(arr);
    return arr;
  };


  useEffect(() => {
    if (ListingRegulationKeyData?.length && ListingRegulationValueData.length) {
      dataSource();
    }
  }, [ListingRegulationKeyData, ListingRegulationValueData]);
   
  const editAssetValue = () => {
    setLoading(true);
  
    const editData = Array.from(modifiedRows).reduce((acc, cellId) => {
      console.log(acc,"ffff")
      if (typeof cellId === "string") {
        const [rowId, colKey] = cellId.split('_');
        const row = data.find((item) => item.id === parseInt(rowId, 10));
        if (!row) return acc;
  
        // Find or initialize an item in acc for this row ID
        let newItem = acc.find((item) => item.id === row.id);
        if (!newItem) {
          newItem = { id: row.id }; // Initialize a new item for this row if not found
          acc.push(newItem);
        }
  
        // Set only modified fields in newItem
        const value = row[colKey]; 
        newItem[colKey] = value;
         
        }
      
      return acc;
    }, []);
  
   
    if (editData.length > 0) {
      ApiPutListingRegulationValue({ regulations: editData })
        .then(() => {
          setLoading(false);
          setModifiedRows(new Set());
          setIsSaveClicked(true);

          dispatch(ListingRegulationKeysThunk());
          dispatch(ListingRegulationValueThunk());
        })
        .catch(() => {
          setLoading(false);
          setIsSaveClicked(false);
        });
    } else {
      setLoading(false); // Handle the case where there is no data to save
    }
  };
  
  const save = async (key) => {
    try {
      const row = await form.validateFields();
      const newData = [...data];
      const index = newData.findIndex((item) => key === item.key);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...row });        
        setData(newData);
        // setModifiedRows(prev => new Set(prev).add(item.id)); 

        Object.keys(row).forEach(colKey => {
          if (row[colKey] !== item[colKey]) { // Only if value has changed
            setModifiedRows(prev => new Set(prev).add(`${item.id}_${colKey}`));
          }
        });
        setEditingKey('');
      }
      else {
        newData.push(row);
        setData(newData);
        setEditingKey('');
      } 
     
    } catch (errInfo) {
      console.log('Validate Failed: ', errInfo);
    }
    setIsSaveClicked(true);   
  };
  useEffect(() => {
    dispatch(ListingRegulationKeysThunk());
    dispatch(ListingRegulationValueThunk());
  }, [isSaveClicked]);

  useEffect(()=>{
    if(modifiedRows && isSaveClicked){
      editAssetValue();
    }
    setIsSaveClicked(false)
  },[modifiedRows,isSaveClicked])

  const columns = () => {
    return [
      {
        title: 'Asset',
        dataIndex: 'name',
        fixed: 'left',
        width: 100,

      },
      ...ListingRegulationKeyData.map((item) => ({
        title:splitStringIntoTwoLines(item.name),
        dataIndex: item.key,
        editable: true,   
        render: (text, record) => {
         return (text);
        },
      })),
      {
        title: 'Operation',
        dataIndex: 'operation',
        fixed: 'right',
        width: 100,
        render: (_, record) => {
          const editable = isEditing(record);         
          return editable ? (
            <span>
              <Typography.Link
                onClick={() => save(record.key)}
                style={{ marginRight: 8 }}
              >
                Save
              </Typography.Link>
              <Popconfirm title="Sure to cancel?" onConfirm={cancel} okText="Yes">
                <a>Cancel</a>               
              </Popconfirm>
            </span>
          ) : (
            <Typography.Link disabled={editingKey !== ''} onClick={() => edit(record)}>
              Edit
            </Typography.Link>
          );
        },
      },
    ];
  };

  const mergedColumns = columns().map((col) => {
    
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.inputType,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
        ListingRegulationValueData
      }),
    };
  });
  return (
    <div>
      {/* <Button className='asset_save' onClick={editAssetValue}>
        SAVE
      </Button> */}
      <Form form={form} component={false} >
        <Table
          loading={ListingRegulationKeyLoading || ListingRegulationValueLoading || loading}
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          className="custom-scroll"
          pagination={false}
          bordered
          dataSource={data}
          columns={mergedColumns}
          rowClassName="editable-row"
          // scroll={{
          //   x: '100vw', 
          //   y: 700, 
          // }}
          scroll={{ y: 500 }}
        />
      </Form>
    </div>
  );
};
