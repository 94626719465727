import React, { useEffect, useState } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import { Avatar, List, Button, Modal, Checkbox } from "antd";
import { useDispatch, useSelector } from "react-redux";

import { cancelAction } from "../../Store/Actions";
import BtnDelete from "../../Components/MyTag/BtnDelete";
import {
  deleteUserThunk,
  ReqularSeparetedThunk,
  userAccessThunk,
  UserAllGetThunk,
} from "../../Store/Thunks/LoginThunk";
import MyLoading from "../../Components/Loading/MyLoading";

export const Clients = () => {
  const dispatch = useDispatch();
  const [clicked, setClicked] = useState(false);
  const [clickedId, setClickedId] = useState(null);
  const [checkedState, setCheckedState] = useState({});
  const { userAll, userAccessLoading, userDeleteSuccess, userLoading } =
    useSelector((state) => state.LoginReducer);

  useEffect(() => {
    dispatch(UserAllGetThunk());
  }, []);

  useEffect(() => {
    const initialCheckedState = {};
    userAll.forEach((user) => {
      initialCheckedState[user.id] =
        user?.listingAssetsUserSettings?.format &&
        user?.listingAssetsUserSettings?.format === "separated"
          ? true
          : false; // Default unchecked
    });
    setCheckedState(initialCheckedState);
  }, [userAll]);
  console.log(checkedState, "checkedState");

  const confirmUserBtn = (id) => {
    dispatch(userAccessThunk(id));
  };

  const deleteUser = (id) => {
    dispatch(deleteUserThunk(id, 1));
    setClicked(true);
  };

  const success = () => {
    Modal.success({
      onOk: (close) => {
        dispatch(cancelAction());
        close();
      },
      content:
        "The user was successfully deleted. You can restore users later from Recycle Bin.",
    });
  };

  useEffect(() => {
    if (userDeleteSuccess && clicked) success();
  }, [userDeleteSuccess, clicked]);

  const handleCheckboxChange = (id, checked) => {
    setCheckedState((prevState) => ({
      ...prevState,
      [id]: checked,
    }));
    const format = checked ? "separated" : "regular";
    dispatch(ReqularSeparetedThunk(id, format));
  };

  return (
    <div className="tabs_container">
      {userLoading ? (
        <MyLoading />
      ) : (
        <List
          className="demo-loadmore-list"
          itemLayout="horizontal"
          dataSource={userAll}
          header={
            <div className="list-header">
              <span className="list-header-title">User</span>
              <span className="list-header-checkbox">
                V - XLSX rows separated
              </span>
              <span className="list-header-actions">Actions</span>
            </div>
          }
          renderItem={(item) => {
            return (
              <List.Item
                actions={[
                  <div>
                    {!item.access ? (
                      <Button
                        loading={userAccessLoading && item.id === clickedId}
                        className="user_access_btn"
                        onClick={() => {
                          setClickedId(item.id);
                          confirmUserBtn(item.id);
                        }}
                      >
                        Approve
                      </Button>
                    ) : null}
                    <Checkbox
                      className="custom-checkbox"
                      checked={checkedState[item.id] || false}
                      onChange={(e) => {
                        handleCheckboxChange(item.id, e.target.checked);
                      }}
                    />
                    <span
                      className={
                        item.access ? "user_access_text" : "user_unaccess_text"
                      }
                    >
                      {item.access ? "Approved " : "Pending"}
                    </span>
                  </div>,

                  <Link to="" key="list-loadmore-edit">
                    edit
                  </Link>,
                  <Link to={`/user/${item.id}`} state={{ name: item.fullName }}>
                    more
                  </Link>,
                  <BtnDelete onClick={() => deleteUser(item.id)} />,
                ]}
              >
                <List.Item.Meta
                  avatar={<Avatar src={item.avatar} />}
                  title={
                    <Link
                      to={`/user/${item.id}`}
                      state={{ name: item.fullName }}
                    >
                      {item.fullName}
                    </Link>
                  }
                  // description={item.email}
                  description={
                    <div>
                      <p>{item.email}</p>
                      <p>{item.phone}</p>
                      <p>
                        {moment(item?.createdAt?.toString())?.format("lll")}
                      </p>
                    </div>
                  }
                />
              </List.Item>
            );
          }}
        />
      )}
    </div>
  );
};
