import {
  LISTING_MARKET_CAP_KEYS_FAIL,
  LISTING_MARKET_CAP_KEYS_REQUEST,
  LISTING_MARKET_CAP_KEYS_SUCCESS,
  LISTING_MARKET_CAP_VALUE_REQUEST,
  LISTING_MARKET_CAP_VALUE_SUCCESS,
  LISTING_MARKET_CAP_VALUE_FAIL,
} from "../Actions";

const listingInitialState = {
  ListingMarketCapKeyData: [],
  ListingMarketCapKeyLoading: false,
  ListingMarketCapKeyError: null,

  ListingMarketCapValueData: [],
  ListingMarketCapValueLoading: false,
  ListingMarketCapValueError: null,
};

export function ListingMarketCapReducer(
  state = listingInitialState,
  { payload, type }
) {
  switch (type) {
    case LISTING_MARKET_CAP_KEYS_REQUEST:
      return {
        ...state,
        ListingMarketCapKeyLoading: true,
        ListingMarketCapKeyData: [],
      };
    case LISTING_MARKET_CAP_KEYS_SUCCESS:
      return {
        ...state,
        ListingMarketCapKeyLoading: false,
        ListingMarketCapKeyData: payload,
      };
    case LISTING_MARKET_CAP_KEYS_FAIL:
      return {
        ...state,
        ListingMarketCapKeyLoading: false,
        ListingMarketCapKeyError: payload,
      };

    case LISTING_MARKET_CAP_VALUE_REQUEST:
      return {
        ...state,
        ListingMarketCapValueLoading: true,
        ListingMarketCapValueData: [],
      };
    case LISTING_MARKET_CAP_VALUE_SUCCESS:
      return {
        ...state,
        ListingMarketCapValueLoading: false,
        ListingMarketCapValueData: payload,
      };
    case LISTING_MARKET_CAP_VALUE_FAIL:
      return {
        ...state,
        ListingMarketCapValueLoading: false,
        ListingMarketCapValueError: payload,
      };

    default:
      return state;
  }
}
