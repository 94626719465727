// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.add_assets {
    width: 40%;
    color: rgb(89, 104, 218);
    margin-bottom: 16px;
}

.add_assets:hover {
    cursor: pointer;
}

.tab_assets_block {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    margin-bottom: 10px;
}

.tab_assets {
    width: 24%;
    color: rgb(89, 104, 218);
    margin-bottom: 16px;
    margin: 3px;
}

.active {
    width: 24%;
    color: rgb(255, 255, 255);
    background-color: #1890ff;
    margin-bottom: 16px;
    margin: 3px;
}

.active:focus {
    color: rgb(255, 255, 255);
    background-color: #1890ff;
}

.asset_save {
    width: 20%;
    margin: 10px 20px 10px 0
}`, "",{"version":3,"sources":["webpack://./src/Pages/Listing/listing.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,wBAAwB;IACxB,mBAAmB;AACvB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,iBAAiB;IACjB,8BAA8B;IAC9B,mBAAmB;AACvB;;AAEA;IACI,UAAU;IACV,wBAAwB;IACxB,mBAAmB;IACnB,WAAW;AACf;;AAEA;IACI,UAAU;IACV,yBAAyB;IACzB,yBAAyB;IACzB,mBAAmB;IACnB,WAAW;AACf;;AAEA;IACI,yBAAyB;IACzB,yBAAyB;AAC7B;;AAEA;IACI,UAAU;IACV;AACJ","sourcesContent":[".add_assets {\n    width: 40%;\n    color: rgb(89, 104, 218);\n    margin-bottom: 16px;\n}\n\n.add_assets:hover {\n    cursor: pointer;\n}\n\n.tab_assets_block {\n    display: flex;\n    flex-wrap: nowrap;\n    justify-content: space-between;\n    margin-bottom: 10px;\n}\n\n.tab_assets {\n    width: 24%;\n    color: rgb(89, 104, 218);\n    margin-bottom: 16px;\n    margin: 3px;\n}\n\n.active {\n    width: 24%;\n    color: rgb(255, 255, 255);\n    background-color: #1890ff;\n    margin-bottom: 16px;\n    margin: 3px;\n}\n\n.active:focus {\n    color: rgb(255, 255, 255);\n    background-color: #1890ff;\n}\n\n.asset_save {\n    width: 20%;\n    margin: 10px 20px 10px 0\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
