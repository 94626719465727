import { applyMiddleware, combineReducers, createStore } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { USER_LOG_OUT } from "./Actions";

// navigation helper
import history from "../Helpers/BrowserHistory";

// reducers
import {
  LoginReducer,
  UserCardsReducer,
  GetLightNtfReducer,
  CreateLightNtfReducer,
  EditLightNtfReducer,
  LightNtfCalcReducer,
  PlatformRiskCalcReducer,
  BlockchainRiskCalcReducer,
  CreatePartnerGuardReducer,
  GetPartnerGuardReducer,
  EditPartnerGuardReducer,
  UserCounterpartyRiskReducer,
  UseLightNTFReducer,
  UseLightDefiReducer,
  AssetChartStatisticReducer,
  BlockchainChartStatisticReducer,
  PlatformChartStatisticReducer,
  LightDefiChartProductSecurityReducer,
  AssetRiskCalculationAllReducer,
  ProductSecurityCalculationReducer,
  ProductRiskCalculationReducer,
  StableCoinEditAssetVisiblityReducer,
  AssetDeleteItemReducer,
  CounterpartyRiskcalculationReducer,
  ListingAssetsReducer,
  ListingHoldersConcentrationReducer,
  ListingMarketCapReducer,
} from "./Reducers";
import { ListingMaturityReducer } from "./Reducers/ListingMaturityReducer";
import { ListingRealVolumeReducer } from "./Reducers/ListingRealVolumeReducer";
import { ListingSecurityReducer } from "./Reducers/ListingSecurityReducer";
import { ListingGeneralReducer } from "./Reducers/ListingGeneralReducer";
import { ListingRegulationReducer } from "./Reducers/ListingRegulationReducer";

// root reducer
const rootReducer = combineReducers({
  LoginReducer,
  UserCardsReducer,
  GetLightNtfReducer,
  CreateLightNtfReducer,
  EditLightNtfReducer,
  LightNtfCalcReducer,
  PlatformRiskCalcReducer,
  BlockchainRiskCalcReducer,
  CreatePartnerGuardReducer,
  GetPartnerGuardReducer,
  EditPartnerGuardReducer,
  UserCounterpartyRiskReducer,
  UseLightNTFReducer,
  UseLightDefiReducer,
  AssetChartStatisticReducer,
  BlockchainChartStatisticReducer,
  PlatformChartStatisticReducer,
  LightDefiChartProductSecurityReducer,
  AssetRiskCalculationAllReducer,
  ProductSecurityCalculationReducer,
  ProductRiskCalculationReducer,
  StableCoinEditAssetVisiblityReducer,
  AssetDeleteItemReducer,
  CounterpartyRiskcalculationReducer,
  ListingAssetsReducer,
  ListingHoldersConcentrationReducer,
  ListingMarketCapReducer,
  ListingMaturityReducer,
  ListingRealVolumeReducer,
  ListingSecurityReducer,
  ListingGeneralReducer,
  ListingRegulationReducer,
});

// initializing whole redux state after logout
export const reducers = (state, action) => {
  if (action.type === USER_LOG_OUT) {
    history.replace("/login");
    return rootReducer(undefined, action);
  }
  return rootReducer(state, action);
};

export const store = createStore(
  reducers,
  composeWithDevTools(applyMiddleware(thunk))
);
