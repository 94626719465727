import {
  LISTING_REGULATION_KEYS_REQUEST,
  LISTING_REGULATION_KEYS_SUCCESS,
  LISTING_REGULATION_KEYS_FAIL,
  LISTING_REGULATION_VALUE_REQUEST,
  LISTING_REGULATION_VALUE_SUCCESS,
  LISTING_REGULATION_VALUE_FAIL,
} from "../Actions";

const listingInitialState = {
  ListingRegulationKeyData: [],
  ListingRegulationKeyLoading: false,
  ListingRegulationKeyError: null,

  ListingRegulationValueData: [],
  ListingRegulationValueLoading: false,
  ListingRegulationValueError: null,
};

export function ListingRegulationReducer(
  state = listingInitialState,
  { payload, type }
) {
  switch (type) {
    case LISTING_REGULATION_KEYS_REQUEST:
      return {
        ...state,
        ListingRegulationKeyLoading: true,
        ListingRegulationKeyData: [],
      };
    case LISTING_REGULATION_KEYS_SUCCESS:
      return {
        ...state,
        ListingRegulationKeyLoading: false,
        ListingRegulationKeyData: payload,
      };
    case LISTING_REGULATION_KEYS_FAIL:
      return {
        ...state,
        ListingRegulationKeyLoading: false,
        ListingRegulationKeyData: payload,
      };

    case LISTING_REGULATION_VALUE_REQUEST:
      return {
        ...state,
        ListingRegulationValueLoading: true,
        ListingRegulationValueData: [],
      };
    case LISTING_REGULATION_VALUE_SUCCESS:
      return {
        ...state,
        ListingRegulationValueLoading: false,
        ListingRegulationValueData: payload,
      };
    case LISTING_REGULATION_VALUE_FAIL:
      return {
        ...state,
        ListingRegulationValueLoading: false,
        ListingRegulationValueError: payload,
      };

    default:
      return state;
  }
}
