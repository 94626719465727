// import axios from 'axios';

// import { store } from '../Store';
// import { USER_LOG_OUT } from '../Store/Actions';
// import history from "../Helpers/BrowserHistory";

// // API axios instance
// const api = axios.create({
//     baseURL: process.env.REACT_APP_BASE_URL,
//     // baseURL: "https://5b6d-130-193-122-202.eu.ngrok.io",
//     headers: {
//         'Content-Type': 'application/json'
//     }
// });

// // interceptor, sending accessToken with authorized requests
// api.interceptors.request.use(async (config) => {
//     const token = localStorage.getItem('accessToken');

//     if (token && (!config.headers.non_auth || config.headers.non_auth === 'false')) {
//         config.headers.Authorization = `Bearer ${token}`;
//     }
//     delete config.headers.non_auth;
//     return config;
// });

// api.interceptors.response.use(
//     function (response) {
//         return response;
//     },
//     function (error) {
//         if (
//             error.response.status === 401
//         ) {
//             store.dispatch({
//                 type: USER_LOG_OUT
//             })
//             history.replace('/login');
//         }
//         return Promise.reject(error);
//     }
// );

/////////////////////////////////////////////

import axios from "axios";
export const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    "Content-Type": "Application/json",
  },
});

api.interceptors.request.use(async (config) => {
  const token = localStorage.getItem("accessToken");
  if (
    token &&
    (!config.headers.non_auth || config.headers.non_auth === "false")
  ) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  delete config.headers.non_auth;
  return config;
});

api.interceptors.response.use(
  async (response) => {
    return response;
  },
  (error) => {
    const { response } = error;
    const { status } = response;
    if (status >= 500) {
      alert("Unknown error, Something went wrong!");
    }
    if (status === 401 && error.config.url === "auth/refresh-token") {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("isAuth");
      window.location.href = "/login";
    }
    throw error;
  }
);

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

const refreshToken = () => {
  return api
    .post("auth/refresh-token", {
      refresh_token: localStorage.getItem("refreshToken"),
    })
    .then(({ data }) => {
      const { data: response } = data;
      localStorage.setItem("refreshToken", response.refresh_token);
      localStorage.setItem("accessToken", response.access_token);
      return {
        accessToken: response.access_token,
      };
    });
};

api.interceptors.response.use(
  (response) => {
    return response;
  },
  async (err) => {
    const originalRequest = err.config;
    if (err.response.status === 401 && !originalRequest._retry) {
      if (isRefreshing) {
        return new Promise(function (resolve, reject) {
          failedQueue.push({
            resolve,
            reject,
          });
        })
          .then((token) => {
            originalRequest.headers["Authorization"] = "Bearer " + token;
            return axios(originalRequest);
          })
          .catch((err) => {
            return Promise.reject(err);
          });
      }

      originalRequest._retry = true;
      isRefreshing = true;
      return refreshToken()
        .then((data) => {
          axios.defaults.headers.common["Authorization"] =
            "Bearer " + data.accessToken;
          originalRequest.headers["Authorization"] =
            "Bearer " + data.accessToken;
          processQueue(null, data.accessToken);
          isRefreshing = false;
          return axios(originalRequest);
        })
        .catch((err) => {
          processQueue(err, null);
          localStorage.removeItem("accessToken");
          localStorage.removeItem("isAuth");
          window.location.href = "/login";
          isRefreshing = false;
        });
    }

    return Promise.reject(err);
  }
);

////////////////////////////////////////

// admin login request
export const loginRequest = ({ email, password }) => {
  return api.post("/auth/login/admin", { email, password });
};

// get assets
export const getAssets = () => {
  return api.get("/defishield/defaults/assets");
};

// create asset request
export const createAsset = ({ stableCoin, symbol, name, defaultValue }) => {
  return api.post("/defishield/defaults/asset", {
    stableCoin,
    symbol,
    name,
    defaultValue,
  });
};

// create blockchain request
export const createBlockChain = ({ assetId, blockchain, platform }) => {
  return api.post("/defishield/defaults/blockchain-platform", {
    assetId,
    blockchain,
    platform,
  });
};

// get Data
export const getTableData = () => {
  return api.get("/defishield/defaults/connections");
};

// delete asset
export const deleteAsset = (id) => {
  return api.delete(`/defishield/defaults/asset/${id}`);
};

// delete platform/blockchain
export const deleteBlockChainAndPlatform = (blockchainId, platformId) => {
  return api.delete(
    `/defishield/defaults/blockchain-platform?blockchainId=${blockchainId.toString()}`
  );
};

// edit asset
export const editAsset = ({
  id,
  stableCoin,
  name,
  symbol,
  defaultValue,
  score,
}) => {
  return api.put(`/defishield/defaults/asset/${id}`, {
    stableCoin,
    name,
    symbol,
    defaultValue,
    score,
  });
};

// edit platform/blockchain
export const editBlockChain = ({ blockchain, platform }) => {
  return api.put("/defishield/defaults/blockchain-platform", {
    blockchain,
    platform,
  });
};

// get all users API
export const userAllApi = () => {
  return api.get(`/user/all`);
};
// regular and separated values for exporting -API
export const reqularSeparetedApi = (userId, format) => {
  return api.post(`/listing-assets-user-settings`, { userId, format });
};

// get user's defi shield cards
export const defishieldUsetListApi = (id) => {
  return api.get(`/defishield/list/all/${id}`);
};

// confirmation for users
export const userAccessApi = (id) => {
  return api.post(`/user/access/${id}`);
};

// delete user
export const DeleteUserApi = (id, type) => {
  return api.delete(`/user/${id}?type=${type}`);
};

// user list for soft delete
export const userDeletedAllApi = () => {
  return api.get(`/user/all/deleted`);
};

// user shield
export const userShieldApi = (userId, type, allow) => {
  return api.put(`/user/shield`, { userId, type, allow });
};

// get user shields
export const GetUserShieldApi = (id) => {
  return api.get(`/user/shields`, {
    params: { userId: id + "" },
  });
};

// Api UnStake
export const ApiUnStake = (id, userId) => {
  return api.post("user/settings/unstake", {
    id,
    userId,
  });
};

export const ApiDeFiShieldAlertUpdate = (defishildId, alert, userId) => {
  return api.put(`defishield/${defishildId}`, {
    alert,
    userId,
  });
};

export const ApiAutoUnstackingUpdate = (
  defishildId,
  autoUnstacking,
  userId
) => {
  return api.put(`defishield/${defishildId}`, {
    autoUnstacking,
    userId,
  });
};

export const ApiDeFiShieldDelete = (defishildId, userId) => {
  return api.delete(`defishield/${defishildId}`, { params: { userId } });
};

export const ApiGetLightNtf = () => {
  return api.get("lightnft/defaults/all");
};

export const ApiCreateLightNtf = (name, risk, image, collectionSlug) => {
  return api.post(
    "lightnft/defaults",
    {
      name,
      risk,
      image,
      collectionSlug,
    },
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
};

export const ApiDeleteLightNtf = (id) => {
  return api.delete(`lightnft/defaults/${id}`, { params: { type: "1" } });
};

export const AipGetLightNtfById = (id) => {
  return api.get(`lightnft/defaults/${id}`);
};

export const ApiEditLightNtf = (
  id,
  name,
  risk,
  image,
  collectionSlug,
  score
) => {
  return api.put(
    `lightnft/defaults/${id}`,
    {
      name,
      risk,
      image,
      collectionSlug,
      score,
    },
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
};

// create calc Params
export const calcParamsApi = (type, scoreDividend, scoreDivisor) => {
  return api.post(`/calc-params`, {
    calcParams: [
      {
        type: type.toString(),
        scoreDividend,
        scoreDivisor,
      },
    ],
  });
};

export const getcalcParamsApi = (type) => {
  return api.get(`/calc-params`, { type });
};

export const putSmartContractAddressApi = (
  contractAddress,
  apiId,
  id,
  decimals,
  apiProvider
) => {
  return api.put(`/defishield/defaults/connections/${id}`, {
    contractAddress,
    apiId,
    decimals,
    apiProvider,
  });
};

export const lightNTFCalc = (type, percent, scoreMultiplier) => {
  return api.post("calc-params", {
    calcParams: [
      {
        type: type.toString(),
        percent,
        scoreMultiplier,
      },
    ],
  });
};

export const CounterPartyCalc = (type, percent, scoreMultiplier) => {
  return api.post("calc-params", {
    calcParams: [
      {
        type: type.toString(),
        percent,
        scoreMultiplier,
      },
    ],
  });
};

export const AssetRiskCalculation = (calcParams) => {
  return api.post("calc-params", {
    calcParams,
  });
};

export const GetCalcParams = () => {
  return api.get("calc-params", { params: { type: 10 } });
};

export const GetCounterPartyCalcParams = () => {
  return api.get("calc-params", { params: { type: 11 } });
};

export const GetPlatformCalcParams = () => {
  return api.get("calc-params", { params: { type: 9 } });
};

export const GetBlockchaiCalcParams = () => {
  return api.get("calc-params", { params: { type: 8 } });
};

export const GetAssetParams = () => {
  return api.get("calc-params", { params: { type: 6 } });
};

export const GetAssetParamsCap = () => {
  return api.get("calc-params", { params: { type: 7 } });
};

export const GetAssetPriceRiskCalcParams = () => {
  return api.get("calc-params", { params: { type: 4 } });
};

export const GetAssetPriceRiskCalcParamsNoStableCoin = () => {
  return api.get("calc-params", { params: { type: 5 } });
};

export const GetAssetMarketPriceRiskCalcParamsNoStableCoin = () => {
  return api.get("calc-params", { params: { type: 6 } });
};

export const GetAssetMarketPriceRiskCalcParams = () => {
  return api.get("calc-params", { params: { type: 6 } });
};

export const GetProtuctRiskCalculatioanAssetParams = () => {
  return api.get("calc-params", { params: { type: 1 } });
};

export const GetProtuctRiskCalculatioanBlockchainParams = () => {
  return api.get("calc-params", { params: { type: 2 } });
};

export const GetProtuctRiskCalculatioanPlatformParams = () => {
  return api.get("calc-params", { params: { type: 3 } });
};

export const ApiCreatePartnerGuard = (
  name,
  risk,
  image,
  tokenId,
  withToken
) => {
  return api.post(
    "partnersguard/defaults",
    {
      name,
      risk,
      image,
      tokenId,
      withToken,
    },
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
};
export const ApiGetPartnerGuard = () => {
  return api.get("partnersguard/defaults/all");
};

export const ApiDeletePartnerGuardItem = (id) => {
  return api.delete(`partnersguard/defaults/${id}`, { params: { type: "1" } });
};

export const ApiEditPartnerGuard = (
  id,
  name,
  risk,
  image,
  tokenId,
  withToken
) => {
  return api.put(
    `partnersguard/defaults/${id}`,
    {
      name,
      risk,
      image,
      tokenId,
      withToken,
    },
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
};

export const ApiGetPartnerGuardById = (id) => {
  return api.get(`partnersguard/defaults/${id}`);
};

export const ApiGrtUserCounterparty = (userId) => {
  return api.get(`partnersguard/products/all/${userId}`);
};

export const ApiDeletrUserCounterparty = (counterpartyId) => {
  return api.delete(`partnersguard/defaults/${counterpartyId}`);
};

export const ApiPartnerGuardAlertUpdate = (id, alert, userId) => {
  return api.put(`partnersguard/${id}`, {
    alert,
    userId: Number(userId),
  });
};

export const ApiGetUserLightNFT = (userId) => {
  return api.get(`lightnft/products/all/${userId}`);
};

export const ApiLightNTFAlertUpdate = (id, alert, userId) => {
  return api.put(`lightnft/${id}`, {
    alert,
    userId: Number(userId),
  });
};

export const ApiGetUserLightDeFi = (userId) => {
  return api.get(`lightdefi/list/all/${userId}`);
};

export const ApiDeleteLightDefi = (id, userId) => {
  return api.delete(`lightdefi/${id}`, {
    params: {
      userId: Number(userId),
    },
  });
};

export const ApiLightDefiAlertUpdate = (id, alert, userId) => {
  return api.put(`lightdefi/${id}`, {
    alert,
    userId: Number(userId),
  });
};

export const ApiStatisticsLightnft = (interval, lightNFTId) => {
  return api.get(
    `statistics/lightnft?interval=${interval}&lightNFTId=${lightNFTId}`
  );
};

export const ApiStatisticsPartnerGuard = (interval, partnerGuardId) => {
  return api.get(
    `statistics/partner-guard?interval=${interval}&partnerGuardId=${partnerGuardId}`
  );
};

export const ApiStatisticsProductSecurity = (interval, connectionId) => {
  return api.get(
    `statistics/product/security?interval=${interval}&connectionId=${connectionId}`
  );
};
export const ApiAssetstatistatics = (interval, assetId) => {
  return api.get(`statistics/asset?interval=${interval}&assetId=${assetId}`);
};

export const ApiBlockchainsstatics = (interval, blockchainId) => {
  return api.get(
    `statistics/blockchain?interval=${interval}&blockchainId=${blockchainId}`
  );
};

export const ApiPlatformstatics = (interval, platformId) => {
  return api.get(
    `statistics/platform?interval=${interval}&platformId=${platformId}`
  );
};

export const GetBlockchainCalcParamsById = (typeId) => {
  return api.get(`defishield/calc-params?typeId=${typeId}`, {
    params: { type: "4" },
  });
};

export const GetAssetCalcParamsById = (assetId, type) => {
  return api.get(`defishield/calc-params?typeId=${assetId}`, {
    params: { type: type },
  });
};

export const GetPlatformCalcParamsById = (typeId) => {
  return api.get(`defishield/calc-params?typeId=${typeId}`, {
    params: { type: "5" },
  }); ////platform risk
};

export const EditCalcParamsById = (
  typeId,
  type,
  scoreDividend,
  scoreDivisor,
  percent,
  scoreMultiplier
) => {
  return api.put(`defishield/calc-params/update`, {
    calcParams: [
      {
        typeId,
        type: type.toString(),
        scoreDividend,
        scoreDivisor,
        percent,
        scoreMultiplier,
      },
    ],
  });
};

export const EditAssetRiskCalculation = (calcParams) => {
  return api.put("defishield/calc-params/update", {
    calcParams,
  });
};

export const ProductRiskCalculation = (calcParams) => {
  return api.post("calc-params", {
    calcParams,
  });
};

export const DeleteAssetItem = (id, type) => {
  return api.delete(`defishield/defaults/asset/${id}`, {
    params: { type: type },
  });
};

export const StablecoinEditAsset = (id) => {
  return api.get(`defishield/defaults/assets/${id}`);
};

export const GetAllDeleteAssets = () => {
  return api.get("defishield/defaults/assets/deleted/all");
};

export const GetAllDeleteLightNFT = () => {
  return api.get("lightnft/defaults/deleted/all");
};

export const DeleteLightNFTItem = (id, type) => {
  return api.delete(`lightnft/defaults/${id}`, { params: { type: type } });
};

export const GetAllDeleteCounterpartyRisk = () => {
  return api.get("partnersguard/defaults/deleted/all");
};

export const DeleteCounterpartyRiskItem = (id, type) => {
  return api.delete(`partnersguard/defaults/${id}`, { params: { type: type } });
};

// export const getListingAssets = () => {
//   return api.get("listing-assets");
// };
export const getListingAssets = (param = null) => {
  const url = param ? `listing-assets?onlyDeleted=true` : "listing-assets";
  return api.get(url);
};

export const getListingSingleAsset = (id) => {
  return api.get(`listing-assets/${id}`);
};

export const createListingAssets = (data) => {
  return api.post("listing-assets", data);
};

export const updateListingAssets = (data, id) => {
  return api.put(`listing-assets/${id}`, data);
};

export const deleteListingAssets = (id, type) => {
  return api.delete(`listing-assets/${id}`, { params: { type: type } });
};

export const ApiGetListingHoldersConcentrationKeys = () => {
  return api.get("listing-holders-concentration/keys");
};

export const ApiGetListingHoldersConcentrationValue = () => {
  return api.get("listing-holders-concentration?search");
};

export const ApiPutListingHoldersConcentrationValue = (data) => {
  return api.put("listing-holders-concentration", {
    holders: data,
  });
};

/*Market Cap Api's */

export const ApiGetListingMarketCapKeys = () => {
  return api.get("listing-market-cap/keys");
};

export const ApiGetListingMarketCapValue = () => {
  return api.get("listing-market-cap?search");
};

export const ApiPutListingMarketCapValue = (data) => {
  return api.put("listing-market-cap", {
    holders: data,
  });
};

/*Maturity Api's */
export const ApiGetListingMaturityKeys = () => {
  return api.get("listing-maturity/keys");
};
export const ApiGetListingMaturityValue = () => {
  return api.get("listing-maturity?search");
};
export const ApiPutListingMaturityValue = (data) => {
  return api.put("listing-maturity", {
    maturities: data,
  });
};

/*RealVolume Api's */
export const ApiGetListingRealVolumeKeys = () => {
  return api.get("listing-real-volume/keys");
};
export const ApiGetListingRealVolumeValue = () => {
  return api.get("listing-real-volume?search");
};
export const ApiPutListingRealVolumeValue = (data) => {
  return api.put("listing-real-volume", data);
};

/*Security Api's */
export const ApiGetListingSecurityKeys = () => {
  return api.get("listing-security/keys");
};
export const ApiGetListingSecurityValue = () => {
  return api.get("listing-security?search");
};
export const ApiPutListingSecurityValue = (data) => {
  return api.put("listing-security", data);
};
/*General Api's */
export const ApiGetListingGeneralKeys = () => {
  return api.get("listing-general/keys");
};
export const ApiGetListingGeneralValue = () => {
  return api.get("listing-general?search");
};
export const ApiPutListingGeneralValue = (data) => {
  return api.put("listing-general", data);
};

/*Regulation Api's */
/*General Api's */
export const ApiGetListingRegulationKeys = () => {
  return api.get("listing-regulation/keys");
};
export const ApiGetListingRegulationValue = () => {
  return api.get("listing-regulation?search");
};
export const ApiPutListingRegulationValue = (data) => {
  return api.put("listing-regulation", data);
};
