import { useState, useEffect } from "react";
import { Space, message, Table, Checkbox } from "antd";

import { useSelector, useDispatch } from "react-redux";
import { DeleteListingAssetsThunk, GetListingAssetsThunk, UpdateListingAssetsThunk } from "../../Store/Thunks/ListingAssetsThunk";

import { ReactComponent as EditIcon } from "../../Assets/img/icons/edit.svg";
import { ReactComponent as DeleteIcon } from "../../Assets/img/icons/delete.svg";

export const AssetsLists = ({ setOpen, setAssetId }) => {
  const dispatch = useDispatch();
  const [messageApi, contextHolder] = message.useMessage();
  const [checkedState, setCheckedState] = useState({}); 

  const assetsList = useSelector(
    (state) => state.ListingAssetsReducer.assetsList
  );
  const created = useSelector((state) => state.ListingAssetsReducer.created);
  const updated = useSelector((state) => state.ListingAssetsReducer.updated);
  const deleted = useSelector((state) => state.ListingAssetsReducer.deleted);

  useEffect(() => {
    dispatch(GetListingAssetsThunk());
  }, []);

  useEffect(() => {
    if (created || updated || deleted) {
      setOpen(false);
      dispatch(GetListingAssetsThunk());
    }
  }, [created, updated, deleted]);

  useEffect(() => {
    if (created) {
      setOpen(false);
    }
  }, [created]);

  const handleCheckboxChange = (id, checked) => {
    setCheckedState((prev) => ({ ...prev, [id]: checked }));
   const show =  checked ? true : false;
     dispatch(UpdateListingAssetsThunk({show:show}, id));  
  };


  const columns = [
    {
      title: "Asset",
      dataIndex: "assetName",
      key: "assetName",
      width: 250,
      ellipsis: true,
    },
    {
      title: "Asset Slug",
      dataIndex: "assetSlug",
      key: "assetSlug",
      width: 250,
      ellipsis: true,
    },
    {
      title: "Maturity",
      dataIndex: "maturity",
      key: "maturity",
    },
    {
      title: "Security",
      dataIndex: "security",
      key: "security",
    },
    {
      title: "Founders",
      dataIndex: "founders",
      key: "founders",
    },
    {
      title: "Reputation",
      dataIndex: "reputation",
      key: "reputation",
    },
    {
      title: "AML",
      dataIndex: "aml",
      key: "aml",
    },
    {
      title:(        
        <Space>
          <span>Actions</span>            
      </Space>
      ),
      width: 150,
      render: (record, { id }) => {
        return (
          <Space size="small">
            <EditIcon
              onClick={() => {
                setOpen(true);
                setAssetId(id);
              }}
            />
            <DeleteIcon
              onClick={() => dispatch(DeleteListingAssetsThunk(id,1))}
            />
            <Checkbox
             className="custom-checkbox"
              checked={record.show ? true : false}
              onChange={(e) => handleCheckboxChange(id, e.target.checked)}
            /> 
          </Space>
        );
      },
    },
  ];

  return (
    <>
      {contextHolder}
      
      <Table
        columns={columns}
        dataSource={assetsList}
        pagination={false}
        scroll={{ y: 500 }}
      />
    </>
  );
};
