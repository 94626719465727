// import React, { useEffect, useState } from "react";
// import moment from "moment/moment";
// import { Avatar, Button, List, Modal, Tabs } from "antd";
// import { useDispatch, useSelector } from "react-redux";

// import { cancelAction } from "../../Store/Actions";
// import BtnDelete from "../../Components/MyTag/BtnDelete";
// import {
//   deleteUserThunk,
//   softDeleteUserThunk,
// } from "../../Store/Thunks/LoginThunk";
// import MyLoading from "../../Components/Loading/MyLoading";

// import {
//   GetAllDeleteAssets,
//   DeleteAssetItem,
//   GetAllDeleteLightNFT,
//   GetAllDeleteCounterpartyRisk,
//   DeleteLightNFTItem,
//   DeleteCounterpartyRiskItem,
// } from "../../Services/index";

// import { TrashAssetsItem } from "../../Components/TrashAssetsItem";

// import { TrashLightNFTItem } from "../../Components/TrashLightNFTItem";
// import { TrashCounterpartyRiskItem } from "../../Components/TrashCounterpartyRiskItem";
// import { AssetsTrash } from "../../Components/AssetInsight/AssetsTrash";

// export const Trash = () => {
//   const dispatch = useDispatch();
//   const { recycledUsers, userLoading } = useSelector(
//     (state) => state.LoginReducer
//   );

//   const [assets, setAssets] = useState([]);

//   const [lightNFT, setLightNFT] = useState([]);

//   const [counterpartyRisk, setCounterpartyRisk] = useState([]);

//   useEffect(() => {
//     dispatch(softDeleteUserThunk());
//   }, []);

//   const RestoreUser = (id) => {
//     dispatch(deleteUserThunk(id, 2));
//   };

//   const success = (id) => {
//     Modal.confirm({
//       okText: "Delete",
//       onOk: (close) => {
//         dispatch(deleteUserThunk(id, 0));
//         dispatch(cancelAction());
//         close();
//       },
//       onCancel: (close) => {
//         close();
//       },
//       content: <div>Are you sure you want to delete this user?</div>,
//     });
//   };

//   useEffect(() => {
//     GetAllDeleteAssets()
//       .then((result) => {
//         setAssets(result?.data?.data?.assets);
//       })
//       .catch((error) => {});
//   }, []);

//   useEffect(() => {
//     GetAllDeleteLightNFT()
//       .then((result) => {
//         setLightNFT(result?.data?.data);
//       })
//       .catch((error) => {});
//   }, []);

//   useEffect(() => {
//     GetAllDeleteCounterpartyRisk()
//       .then((result) => {
//         setCounterpartyRisk(result?.data?.data);
//       })
//       .catch((error) => {});
//   }, []);

//   return (
//     <Tabs defaultActiveKey="1" style={{ width: "70%" }}>
//       <Tabs.TabPane tab="Deleted Users" key="1">
//         <div className="tabs_container">
//           {userLoading ? (
//             <MyLoading />
//           ) : (
//             <List
//               className="demo-loadmore-list"
//               itemLayout="horizontal"
//               dataSource={recycledUsers}
//               renderItem={(item) => {
//                 return (
//                   <List.Item
//                     key={item.id}
//                     actions={[
//                       <Button
//                         className="user_access_btn"
//                         onClick={() => RestoreUser(item.id)}
//                       >
//                         Restore
//                       </Button>,
//                       <BtnDelete onClick={() => success(item.id)} />,
//                     ]}
//                   >
//                     <List.Item.Meta
//                       avatar={<Avatar src={item.avatar} />}
//                       title={<span>{item.fullName}</span>}
//                       description={
//                         <div>
//                           <p>{item.email}</p>
//                           <p>
//                             {moment(item?.createdAt?.toString())?.format("lll")}
//                           </p>
//                         </div>
//                       }
//                     />
//                   </List.Item>
//                 );
//               }}
//             />
//           )}
//           {assets?.length ? (
//             <div>
//               <p className="defi__shild__trash__title">DeFi Shield Product:</p>
//               {assets.map((element, index) => {
//                 return (
//                   <TrashAssetsItem
//                     key={index + 1}
//                     element={element}
//                     deleteItem={(event) => {
//                       DeleteAssetItem(event, 0).then((result) => {
//                         if (result) {
//                           GetAllDeleteAssets()
//                             .then((result) => {
//                               setAssets(result?.data?.data?.assets);
//                             })
//                             .catch((error) => {});
//                         }
//                       });
//                     }}
//                     restoreItem={(event) => {
//                       DeleteAssetItem(event, 2).then((result) => {
//                         if (result) {
//                           GetAllDeleteAssets()
//                             .then((result) => {
//                               setAssets(result?.data?.data?.assets);
//                             })
//                             .catch((error) => {});
//                         }
//                       });
//                     }}
//                   />
//                 );
//               })}
//             </div>
//           ) : null}

//           <div>
//             {lightNFT?.length ? (
//               <div style={{ margin: "20px 0" }}>
//                 <p className="defi__shild__trash__title">Light NFT Product:</p>
//                 {lightNFT.map((element, index) => {
//                   return (
//                     <TrashLightNFTItem
//                       key={index + 1}
//                       element={element}
//                       deleteItem={(event) => {
//                         DeleteLightNFTItem(event, 0).then((result) => {
//                           if (result) {
//                             GetAllDeleteLightNFT()
//                               .then((result) => {
//                                 setLightNFT(result?.data?.data);
//                               })
//                               .catch((error) => {});
//                           }
//                         });
//                       }}
//                       restoreItem={(event) => {
//                         DeleteLightNFTItem(event, 2).then((result) => {
//                           if (result) {
//                             GetAllDeleteLightNFT()
//                               .then((result) => {
//                                 setLightNFT(result?.data?.data);
//                               })
//                               .catch((error) => {});
//                           }
//                         });
//                       }}
//                     />
//                   );
//                 })}
//               </div>
//             ) : null}
//           </div>
//           <div>
//             {counterpartyRisk?.length ? (
//               <div style={{ margin: "20px 0" }}>
//                 <p className="defi__shild__trash__title">
//                   Counterparty Risk Product:
//                 </p>
//                 {counterpartyRisk.map((element, index) => {
//                   return (
//                     <TrashCounterpartyRiskItem
//                       key={index + 1}
//                       element={element}
//                       deleteItem={(event) => {
//                         DeleteCounterpartyRiskItem(event, 0).then((result) => {
//                           if (result) {
//                             GetAllDeleteCounterpartyRisk()
//                               .then((result) => {
//                                 setCounterpartyRisk(result?.data?.data);
//                               })
//                               .catch((error) => {});
//                           }
//                         });
//                       }}
//                       restoreItem={(event) => {
//                         DeleteCounterpartyRiskItem(event, 2).then((result) => {
//                           if (result) {
//                             GetAllDeleteCounterpartyRisk()
//                               .then((result) => {
//                                 setCounterpartyRisk(result?.data?.data);
//                               })
//                               .catch((error) => {});
//                           }
//                         });
//                       }}
//                     />
//                   );
//                 })}
//               </div>
//             ) : null}
//           </div>
//         </div>
//       </Tabs.TabPane>
//       <Tabs.TabPane tab="Deleted Assets" key="2">
//         <div style={{ padding: 20, textAlign: "center" }}>
//           <AssetsTrash />
//         </div>
//       </Tabs.TabPane>
//     </Tabs>
//   );
// };

import React, { useEffect, useState } from "react";
import moment from "moment/moment";
import { Avatar, Button, List, Modal, Tabs } from "antd";
import { useDispatch, useSelector } from "react-redux";

import { cancelAction } from "../../Store/Actions";
import BtnDelete from "../../Components/MyTag/BtnDelete";
import {
  deleteUserThunk,
  softDeleteUserThunk,
} from "../../Store/Thunks/LoginThunk";
import MyLoading from "../../Components/Loading/MyLoading";

import {
  GetAllDeleteAssets,
  DeleteAssetItem,
  GetAllDeleteLightNFT,
  GetAllDeleteCounterpartyRisk,
  DeleteLightNFTItem,
  DeleteCounterpartyRiskItem,
} from "../../Services/index";

import { TrashAssetsItem } from "../../Components/TrashAssetsItem";

import { TrashLightNFTItem } from "../../Components/TrashLightNFTItem";
import { TrashCounterpartyRiskItem } from "../../Components/TrashCounterpartyRiskItem";

export const Trash = () => {
  const dispatch = useDispatch();
  const { recycledUsers, userLoading } = useSelector(
    (state) => state.LoginReducer
  );

  const [assets, setAssets] = useState([]);

  const [lightNFT, setLightNFT] = useState([]);

  const [counterpartyRisk, setCounterpartyRisk] = useState([]);

  useEffect(() => {
    dispatch(softDeleteUserThunk());
  }, []);

  const RestoreUser = (id) => {
    dispatch(deleteUserThunk(id, 2));
  };

  const success = (id) => {
    Modal.confirm({
      okText: "Delete",
      onOk: (close) => {
        dispatch(deleteUserThunk(id, 0));
        dispatch(cancelAction());
        close();
      },
      onCancel: (close) => {
        close();
      },
      content: <div>Are you sure you want to delete this user?</div>,
    });
  };

  useEffect(() => {
    GetAllDeleteAssets()
      .then((result) => {
        setAssets(result?.data?.data?.assets);
      })
      .catch((error) => {});
  }, []);

  useEffect(() => {
    GetAllDeleteLightNFT()
      .then((result) => {
        setLightNFT(result?.data?.data);
      })
      .catch((error) => {});
  }, []);

  useEffect(() => {
    GetAllDeleteCounterpartyRisk()
      .then((result) => {
        setCounterpartyRisk(result?.data?.data);
      })
      .catch((error) => {});
  }, []);

  return (
    <div className="tabs_container">
      {userLoading ? (
        <MyLoading />
      ) : (
        <List
          className="demo-loadmore-list"
          itemLayout="horizontal"
          dataSource={recycledUsers}
          renderItem={(item) => {
            return (
              <List.Item
                key={item.id}
                actions={[
                  <Button
                    className="user_access_btn"
                    onClick={() => RestoreUser(item.id)}
                  >
                    Restore
                  </Button>,
                  <BtnDelete onClick={() => success(item.id)} />,
                ]}
              >
                <List.Item.Meta
                  avatar={<Avatar src={item.avatar} />}
                  title={<span>{item.fullName}</span>}
                  description={
                    <div>
                      <p>{item.email}</p>
                      <p>
                        {moment(item?.createdAt?.toString())?.format("lll")}
                      </p>
                    </div>
                  }
                />
              </List.Item>
            );
          }}
        />
      )}
      {assets?.length ? (
        <div>
          <p className="defi__shild__trash__title">DeFi Shield Product:</p>
          {assets.map((element, index) => {
            return (
              <TrashAssetsItem
                key={index + 1}
                element={element}
                deleteItem={(event) => {
                  DeleteAssetItem(event, 0).then((result) => {
                    if (result) {
                      GetAllDeleteAssets()
                        .then((result) => {
                          setAssets(result?.data?.data?.assets);
                        })
                        .catch((error) => {});
                    }
                  });
                }}
                restoreItem={(event) => {
                  DeleteAssetItem(event, 2).then((result) => {
                    if (result) {
                      GetAllDeleteAssets()
                        .then((result) => {
                          setAssets(result?.data?.data?.assets);
                        })
                        .catch((error) => {});
                    }
                  });
                }}
              />
            );
          })}
        </div>
      ) : null}

      <div>
        {lightNFT?.length ? (
          <div style={{ margin: "20px 0" }}>
            <p className="defi__shild__trash__title">Light NFT Product:</p>
            {lightNFT.map((element, index) => {
              return (
                <TrashLightNFTItem
                  key={index + 1}
                  element={element}
                  deleteItem={(event) => {
                    DeleteLightNFTItem(event, 0).then((result) => {
                      if (result) {
                        GetAllDeleteLightNFT()
                          .then((result) => {
                            setLightNFT(result?.data?.data);
                          })
                          .catch((error) => {});
                      }
                    });
                  }}
                  restoreItem={(event) => {
                    DeleteLightNFTItem(event, 2).then((result) => {
                      if (result) {
                        GetAllDeleteLightNFT()
                          .then((result) => {
                            setLightNFT(result?.data?.data);
                          })
                          .catch((error) => {});
                      }
                    });
                  }}
                />
              );
            })}
          </div>
        ) : null}
      </div>
      <div>
        {counterpartyRisk?.length ? (
          <div style={{ margin: "20px 0" }}>
            <p className="defi__shild__trash__title">
              Counterparty Risk Product:
            </p>
            {counterpartyRisk.map((element, index) => {
              return (
                <TrashCounterpartyRiskItem
                  key={index + 1}
                  element={element}
                  deleteItem={(event) => {
                    DeleteCounterpartyRiskItem(event, 0).then((result) => {
                      if (result) {
                        GetAllDeleteCounterpartyRisk()
                          .then((result) => {
                            setCounterpartyRisk(result?.data?.data);
                          })
                          .catch((error) => {});
                      }
                    });
                  }}
                  restoreItem={(event) => {
                    DeleteCounterpartyRiskItem(event, 2).then((result) => {
                      if (result) {
                        GetAllDeleteCounterpartyRisk()
                          .then((result) => {
                            setCounterpartyRisk(result?.data?.data);
                          })
                          .catch((error) => {});
                      }
                    });
                  }}
                />
              );
            })}
          </div>
        ) : null}
      </div>
    </div>
  );
};
