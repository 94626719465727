import React, { useState, useEffect } from 'react';
import { Button, Form, Input, InputNumber, Popconfirm, Select, Table, Typography } from 'antd';
import { useDispatch, useSelector } from "react-redux";
import { ApiPutListingMaturityValue, ApiPutListingSecurityValue } from '../../Services';
import { ListingMaturityKeysThunk, ListingMaturityValueThunk } from '../../Store/Thunks/ListingMaturityThunk';
import _ from 'lodash';
import { _capitalize } from 'chart.js/helpers';
import { stringToNumber } from '../../Helpers/ValueFormater';
import { ListingSecurityKeysThunk, ListingSecurityValueThunk } from '../../Store/Thunks/ListingSecurityThunk';

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ListingSecurityValueData,
  ...restProps
}) => {
  const options = {
    Low: { value: "Low", style: { color: '#4DC7B5' }, label: "Low" },
    Medium: { value: "Medium", style: { color: '#F5B452' }, label: "Medium" },  
    High: { value: "High", style: { color: '#F27281' }, label: "High" }
  };
 
  return (
    <td {...restProps}>
      {editing ? (<>
        <Form.Item
          name={`${dataIndex}Level`}
          style={{ margin: 0 }}
        >
               <Select>
        {Object.keys(options).map(key => (
          <Select.Option key={options[key].value} value={options[key].value} style={options[key].style}>
            {options[key].label}
          </Select.Option>
        ))}
      </Select>   
        </Form.Item>
          <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
        >          
      <Input/>
        </Form.Item>
        </>
      ) : (
        record ? <> <p>{record[`${dataIndex}Level`]} </p> <p>{record[dataIndex]} </p> </> : children 
      )}
    </td>
  );
};

export default EditableCell;
export const Security = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [editingKey, setEditingKey] = useState('');
  const [isSaveClicked, setIsSaveClicked] = useState(false);
  const [modifiedRows, setModifiedRows] = useState(new Set());

  useEffect(() => {
    dispatch(ListingSecurityKeysThunk());
    dispatch(ListingSecurityValueThunk());
  }, []);

 

  const {
    ListingSecurityKeyData,
    ListingSecurityKeyLoading,
    ListingSecurityValueData,
    ListingSecurityValueLoading,
  } = useSelector((state) => state.ListingSecurityReducer);

  const isEditing = (record) => record.key === editingKey;

  const edit = (record) => {     
    form.setFieldsValue({ ...record });
    setEditingKey(record.key);
  };

  const cancel = () => {
    setEditingKey('');
  };
  const reorderKeys = (keysArray) => {
    const reorderedKeys = [];
    const priorityKeys = ["smartContractPlatformSecurityAudit", "projectSecurity"];
  
    // Push priority keys in desired order
    priorityKeys.forEach((key) => {
      const match = keysArray.find((item) => item.key === key);
      if (match) reorderedKeys.push(match);
    });
  
    // Add remaining keys
    keysArray.forEach((item) => {
      if (!priorityKeys.includes(item.key)) {
        reorderedKeys.push(item);
      }
    });
  
    return reorderedKeys;
  };
  const reorderedKeys = reorderKeys(ListingSecurityKeyData);

  const dataSource = () => {
    const arr = [];
    ListingSecurityValueData.map((item, index) => {
      const obj = {};
      ListingSecurityKeyData.map((element) => {
        if (item.listingSecurity) {         
          obj[element.key] = item.listingSecurity[element.key];
          obj[`${element.key}Level`] = item.listingSecurity[`${element.key}Level`];
          obj.id = item.listingSecurity.id;
        } else {       
          obj[element.key] = "";
          obj[`${element.key}Level`] = "";
          obj.id = item.id;
        }
      });
      
      arr.push({
        name: item.assetName,
        ...obj,
        key: index,
      });
    });
   
    setData(arr);
    return arr;
  };

  useEffect(() => {
    if (ListingSecurityKeyData?.length && ListingSecurityValueData.length) {
      dataSource();
    }
  }, [ListingSecurityKeyData, ListingSecurityValueData]);

  const editAssetValue = () => {
    setLoading(true);
    const editData = Array.from(modifiedRows).map((id) => {
      const row = data.find((item) => item.id === id);
      const newItem = {};
      for (const key in row) {
        if (key !== 'key' && key !== 'name') {        
          newItem[key] = row[key];
        }
      }
      return newItem;
    });
    ApiPutListingSecurityValue({"securities":editData}).then(() => {
      setLoading(false);
      setModifiedRows(new Set());       
    }).catch(() => {
      setLoading(false);
    });
  };
  const save = async (key) => {
    try {
      const row = await form.validateFields();
      const newData = [...data];
      const index = newData.findIndex((item) => key === item.key);

      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...row });        
        setData(newData);
        setModifiedRows(prev => new Set(prev).add(item.id)); 
        setEditingKey('');
      }
      setIsSaveClicked(true);
   
    } catch (errInfo) {
      console.log('Validate Failed: ', errInfo);
    }
  };
  useEffect(()=>{
    if(modifiedRows && isSaveClicked){
      editAssetValue();
    }
    setIsSaveClicked(false)
  },[modifiedRows,isSaveClicked])
  
  const columns = () => {
    return [
      {
        title: 'Asset',
        dataIndex: 'name',
        fixed: 'left',
        width: 100,

      },
      ...reorderedKeys.map((item) => ({
        title: item.name,
        dataIndex: item.key,
        editable: true,
        // width: 100, 
        
      })),
      {
        title: 'Operation',
        dataIndex: 'operation',
        fixed: 'right',
        width: 100,
        render: (_, record) => {
          const editable = isEditing(record);         
          return editable ? (
            <span>
              <Typography.Link
                onClick={() => save(record.key)}
                style={{ marginRight: 8 }}
              >
                Save
              </Typography.Link>
              <Popconfirm title="Sure to cancel?" onConfirm={cancel} okText="Yes">
                <a>Cancel</a>               
              </Popconfirm>
            </span>
          ) : (
            <Typography.Link disabled={editingKey !== ''} onClick={() => edit(record)}>
              Edit
            </Typography.Link>
          );
        },
      },
    ];
  };

  const mergedColumns = columns().map((col) => {
    
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.inputType,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
        ListingSecurityValueData
      }),
    };
  });



  return (
    <div>
      {/* <Button className='asset_save' onClick={editAssetValue}>
        SAVE
      </Button> */}
      <Form form={form} component={false} >
        <Table
          loading={ListingSecurityKeyLoading || ListingSecurityValueLoading || loading}
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          className="custom-scroll"
          pagination={false}
          bordered
          dataSource={data}
          columns={mergedColumns}
          rowClassName="editable-row"
          // scroll={{
          //   x: '100vw', 
          //   y: 700, 
          // }}
          scroll={{ y: 500 }}
        />
      </Form>
    </div>
  );
};
