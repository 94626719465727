import React, { useEffect, useState } from 'react';
import moment from "moment/moment";
import { Avatar, Button, List, Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";

import { cancelAction } from "../../Store/Actions";
import BtnDelete from "../../Components/MyTag/BtnDelete";
import { deleteUserThunk, softDeleteUserThunk } from "../../Store/Thunks/LoginThunk";
import MyLoading from "../../Components/Loading/MyLoading";

import { 
    GetAllDeleteAssets,

} from '../../Services/index';
import { DeleteListingAssetsThunk, GetListingAssetsThunk } from '../../Store/Thunks/ListingAssetsThunk';


export const AssetsTrash = () => {

    const dispatch = useDispatch();

    const assetsList = useSelector(
       (state) => state.ListingAssetsReducer.assetsList
     );    
   
     useEffect(() => {
       dispatch(GetListingAssetsThunk("trash"));
     }, []);

     console.log(assetsList,"assetsList")

    const RestoreAssets = (id,type) => {
        dispatch(DeleteListingAssetsThunk(id, type))
    };

    const success = (id) => {
        Modal.confirm({
            okText: 'Delete',
            onOk: (close) => {
                dispatch(deleteUserThunk(id, 0))
                dispatch(cancelAction())
                close();
            },
            onCancel: (close) => {
                close();
            },
            content: <div>Are you sure you want to delete this user?</div>
        });
    };


    // useEffect(() => {

    //     GetAllDeleteAssets()
    //         .then((result) => {
    //             setAssets(result?.data?.data?.assets)
    //         })
    //         .catch((error) => { })
    // }, []);


    return (
        <div className="tabs_container">
            {
                !assetsList ?
                    <MyLoading />
                    :
                    <List
                        className="demo-loadmore-list"
                        itemLayout="horizontal"
                        dataSource={assetsList}
                        renderItem={(item) => {
                            return <List.Item
                                key={item.id}
                                actions={[
                                    <Button className="user_access_btn" onClick={() => RestoreAssets(item.id,2)}>Restore</Button>,
                                    <BtnDelete onClick={() => success(item.id)} />
                                ]}>

                                <List.Item.Meta
                                    // avatar={<Avatar src={item.assetName} />}
                                    title={<span>{item.assetName}</span>}
                                    description={
                                        <div>
                                            
                                        </div>}
                                />
                            </List.Item>
                        }}
                    />
            }

        </div>


    );
};