import {
  ApiGetListingMarketCapKeys,
  ApiGetListingMarketCapValue,
} from "../../Services";
import {
  LISTING_MARKET_CAP_KEYS_REQUEST,
  LISTING_MARKET_CAP_KEYS_SUCCESS,
  LISTING_MARKET_CAP_KEYS_FAIL,
  LISTING_MARKET_CAP_VALUE_REQUEST,
  LISTING_MARKET_CAP_VALUE_SUCCESS,
  LISTING_MARKET_CAP_VALUE_FAIL,
} from "../Actions";

export const ListingMarketCapKeysThunk = () => {
  return async (dispatch) => {
    dispatch({
      type: LISTING_MARKET_CAP_KEYS_REQUEST,
    });
    try {
      const response = await ApiGetListingMarketCapKeys();
      const { data } = response;
      dispatch({
        type: LISTING_MARKET_CAP_KEYS_SUCCESS,
        payload: data?.data,
      });
    } catch ({ response }) {
      const { data } = response;
      dispatch({
        type: LISTING_MARKET_CAP_KEYS_FAIL,
        payload:
          typeof data?.error?.message === "string"
            ? data?.error?.message
            : data?.error?.message[0],
      });
    }
  };
};

export const ListingMarketCapValueThunk = () => {
  return async (dispatch) => {
    dispatch({
      type: LISTING_MARKET_CAP_VALUE_REQUEST,
    });
    try {
      const response = await ApiGetListingMarketCapValue();
      const { data } = response;
      dispatch({
        type: LISTING_MARKET_CAP_VALUE_SUCCESS,
        payload: data?.data,
      });
    } catch ({ response }) {
      const { data } = response;
      dispatch({
        type: LISTING_MARKET_CAP_VALUE_FAIL,
        payload:
          typeof data?.error?.message === "string"
            ? data?.error?.message
            : data?.error?.message[0],
      });
    }
  };
};
