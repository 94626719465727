import {
  ApiGetListingHoldersConcentrationKeys,
  ApiGetListingHoldersConcentrationValue,
} from "../../Services";
import {
  LISTING_HOLDERS_CONCENTRATION_KEYS_FAIL,
  LISTING_HOLDERS_CONCENTRATION_KEYS_REQUEST,
  LISTING_HOLDERS_CONCENTRATION_KEYS_SUCCESS,
  LISTING_HOLDERS_CONCENTRATION_VALUE_REQUEST,
  LISTING_HOLDERS_CONCENTRATION_VALUE_SUCCESS,
  LISTING_HOLDERS_CONCENTRATION_VALUE_FAIL,
} from "../Actions";

export const ListingHoldersConcentrationKeysThunk = () => {
  return async (dispatch) => {
    dispatch({
      type: LISTING_HOLDERS_CONCENTRATION_KEYS_REQUEST,
    });
    try {
      const response = await ApiGetListingHoldersConcentrationKeys();
      const { data } = response;
      dispatch({
        type: LISTING_HOLDERS_CONCENTRATION_KEYS_SUCCESS,
        payload: data?.data,
      });
    } catch ({ response }) {
      const { data } = response;
      dispatch({
        type: LISTING_HOLDERS_CONCENTRATION_KEYS_FAIL,
        payload:
          typeof data?.error?.message === "string"
            ? data?.error?.message
            : data?.error?.message[0],
      });
    }
  };
};

export const ListingHoldersConcentrationValueThunk = () => {
  return async (dispatch) => {
    dispatch({
      type: LISTING_HOLDERS_CONCENTRATION_VALUE_REQUEST,
    });
    try {
      const response = await ApiGetListingHoldersConcentrationValue();
      const { data } = response;
      dispatch({
        type: LISTING_HOLDERS_CONCENTRATION_VALUE_SUCCESS,
        payload: data?.data,
      });
    } catch ({ response }) {
      const { data } = response;
      dispatch({
        type: LISTING_HOLDERS_CONCENTRATION_VALUE_FAIL,
        payload:
          typeof data?.error?.message === "string"
            ? data?.error?.message
            : data?.error?.message[0],
      });
    }
  };
};
