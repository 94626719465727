export const CANCEL_ACTION = "CANCEL_ACTION";
export function cancelAction() {
  return {
    type: CANCEL_ACTION,
    payload: {},
  };
}

export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGIN_LOADING = "LOGIN_LOADING";

export const LOGIN_SUCESS_STATE = "LOGIN_SUCESS_STATE";
export const DELETE_FAILED_STATUS = "DELETE_FAILED_STATUS";
export const USER_LOG_OUT = "USER_LOG_OUT";

export const USER_ALL_REQUEST = "USER_ALL_REQUEST";
export const USER_ALL_SUCCESS = "USER_ALL_SUCCESS";
export const USER_ALL_FAIL = "USER_ALL_FAIL";

export const REGULAR_SEPARATE_REQUEST = "REGULAR_SEPARATE_REQUEST";
export const REGULAR_SEPARATE_SUCCESS = "REGULAR_SEPARATE_SUCCESS";
export const REGULAR_SEPARATE_FAIL = "REGULAR_SEPARATE_FAIL";

export const GET_DEFISHIELD_REQUEST = "GET_DEFISHIELD_REQUEST";
export const GET_DEFISHIELD_SUCCESS = "GET_DEFISHIELD_SUCCESS";
export const GET_DEFISHIELD_FAIL = "GET_DEFISHIELD_FAIL";

export const USER_ACCESS_REQUEST = "USER_ACCESS_REQUEST";
export const USER_ACCESS_SUCCESS = "USER_ACCESS_SUCCESS";
export const USER_ACCESS_FAIL = "USER_ACCESS_FAIL";

export const DELETE_USER_REQUEST = "DELETE_USER_REQUEST";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAIL = "DELETE_USER_FAIL";

export const SOFT_DELETE_USER_REQUEST = "SOFT_DELETE_USER_REQUEST";
export const SOFT_DELETE_USER_SUCCESS = "SOFT_DELETE_USER_SUCCESS";
export const SOFT_DELETE_USER_FAIL = "SOFT_DELETE_USER_FAIL";

export const USER_SHIELD_REQUEST = "USER_SHIELD_REQUEST";
export const USER_SHIELD_SUCCESS = "USER_SHIELD_SUCCESS";
export const USER_SHIELD_FAIL = "USER_SHIELD_FAIL";

export const GET_USER_SHIELDS_REQUEST = "GET_USER_SHIELDS_REQUEST";
export const GET_USER_SHIELDS_SUCCESS = "GET_USER_SHIELDS_SUCCESS";
export const GET_USER_SHIELDS_FAIL = "GET_USER_SHIELDS_FAIL";

export const UN_STAKE_REQUEST = "UN_STAKE_REQUEST";
export const UN_STAKE_SUCCESS = "UN_STAKE_SUCCESS";
export const UN_STAKE_FAIL = "UN_STAKE_FAIL";

export const DE_FI_SHIELD_ALERT_UPDATE_REQUEST =
  "DE_FI_SHIELD_ALERT_UPDATE_REQUEST";
export const DE_FI_SHIELD_ALERT_UPDATE_SUCCESS =
  "DE_FI_SHIELD_ALERT_UPDATE_SUCCESS";
export const DE_FI_SHIELD_ALERT_UPDATE_FAIL = "DE_FI_SHIELD_ALERT_UPDATE_FAIL";

export const AUTO_UNSTACKING_UPDATE_REQUEST = "AUTO_UNSTACKING_UPDATE_REQUEST";
export const AUTO_UNSTACKING_UPDATE_SUCCESS = "AUTO_UNSTACKING_UPDATE_SUCCESS";
export const AUTO_UNSTACKING_UPDATE_FAIL = "AUTO_UNSTACKING_UPDATE_FAIL";

export const CALE_PARAMS_REQUEST = "CALE_PARAMS_REQUEST";
export const CALE_PARAMS_SUCCESS = "CALE_PARAMS_SUCCESS";
export const CALE_PARAMS_FAIL = "CALE_PARAMS_FAIL";

export const LISTING_HOLDERS_CONCENTRATION_KEYS_REQUEST =
  "LISTING_HOLDERS_CONCENTRATION_KEYS_REQUEST";
export const LISTING_HOLDERS_CONCENTRATION_KEYS_SUCCESS =
  "LISTING_HOLDERS_CONCENTRATION_KEYS_SUCCESS";
export const LISTING_HOLDERS_CONCENTRATION_KEYS_FAIL =
  "LISTING_HOLDERS_CONCENTRATION_KEYS_FAIL";

export const LISTING_HOLDERS_CONCENTRATION_VALUE_REQUEST =
  "LISTING_HOLDERS_CONCENTRATION_VALUE_REQUEST";
export const LISTING_HOLDERS_CONCENTRATION_VALUE_SUCCESS =
  "LISTING_HOLDERS_CONCENTRATION_VALUE_SUCCESS";
export const LISTING_HOLDERS_CONCENTRATION_VALUE_FAIL =
  "LISTING_HOLDERS_CONCENTRATION_VALUE_FAIL";

/*Market Cap Contstants */
export const LISTING_MARKET_CAP_KEYS_REQUEST =
  "LISTING_MARKET_CAP_KEYS_REQUEST";
export const LISTING_MARKET_CAP_KEYS_SUCCESS =
  "LISTING_MARKET_CAP_KEYS_SUCCESS";
export const LISTING_MARKET_CAP_KEYS_FAIL = "LISTING_MARKET_CAP_KEYS_FAIL";

export const LISTING_MARKET_CAP_VALUE_REQUEST =
  "LISTING_HOLDERS_CONCENTRATION_VALUE_REQUEST";
export const LISTING_MARKET_CAP_VALUE_SUCCESS =
  "LISTING_MARKET_CAP_VALUE_SUCCESS";
export const LISTING_MARKET_CAP_VALUE_FAIL = "LISTING_MARKET_CAP_VALUE_FAIL";

export const LISTING_REALVOLUME_KEYS_REQUEST =
  "LISTING_REALVOLUME_KEYS_REQUEST";
export const LISTING_REALVOLUME_KEYS_SUCCESS =
  "LISTING_REALVOLUME_KEYS_SUCCESS";
export const LISTING_REALVOLUME_KEYS_FAIL = "LISTING_REALVOLUME_KEYS_FAIL";

export const LISTING_REALVOLUME_VALUE_REQUEST =
  "LISTING_REALVOLUME_VALUE_REQUEST";
export const LISTING_REALVOLUME_VALUE_SUCCESS =
  "LISTING_REALVOLUME_VALUE_SUCCESS";
export const LISTING_REALVOLUME_VALUE_FAIL = "LISTING_REALVOLUME_VALUE_FAIL";

export const LISTING_MATURITY_KEYS_REQUEST = "LISTING_MATURITY_KEYS_REQUEST";
export const LISTING_MATURITY_KEYS_SUCCESS = "LISTING_MATURITY_KEYS_SUCCESS";
export const LISTING_MATURITY_KEYS_FAIL = "LISTING_MATURITY_KEYS_FAIL";

export const LISTING_MATURITY_VALUE_REQUEST = "LISTING_MATURITY_VALUE_REQUEST";
export const LISTING_MATURITY_VALUE_SUCCESS = "LISTING_MATURITY_VALUE_SUCCESS";
export const LISTING_MATURITY_VALUE_FAIL = "LISTING_MATURITY_VALUE_FAIL";

export const LISTING_SECURITY_KEYS_REQUEST = "LISTING_SECURITY_KEYS_REQUEST";
export const LISTING_SECURITY_KEYS_SUCCESS = "LISTING_SECURITY_KEYS_SUCCESS";
export const LISTING_SECURITY_KEYS_FAIL = "LISTING_SECURITY_KEYS_FAIL";

export const LISTING_SECURITY_VALUE_REQUEST = "LISTING_SECURITY_VALUE_REQUEST";
export const LISTING_SECURITY_VALUE_SUCCESS = "LISTING_SECURITY_VALUE_SUCCESS";
export const LISTING_SECURITY_VALUE_FAIL = "LISTING_SECURITY_VALUE_FAIL";

export const LISTING_GENERAL_KEYS_REQUEST = "LISTING_GENERAL_KEYS_REQUEST";
export const LISTING_GENERAL_KEYS_SUCCESS = "LISTING_GENERAL_KEYS_SUCCESS";
export const LISTING_GENERAL_KEYS_FAIL = "LISTING_GENERAL_KEYS_FAIL";

export const LISTING_GENERAL_VALUE_REQUEST = "LISTING_GENERAL_VALUE_REQUEST";
export const LISTING_GENERAL_VALUE_SUCCESS = "LISTING_GENERAL_VALUE_SUCCESS";
export const LISTING_GENERAL_VALUE_FAIL = "LISTING_GENERAL_VALUE_FAIL";
