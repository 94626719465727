import React, { useState, useEffect } from "react";
import {
  Button,
  Form,
  Input,
  InputNumber,
  Popconfirm,
  Table,
  Typography,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { ApiPutListingRealVolumeValue } from "../../Services";
import {
  ListingRealVolumeKeysThunk,
  ListingRealVolumeValueThunk,
} from "../../Store/Thunks/ListingRealVolumeThunk";
import ReloadIcon from "../../Assets/img/icons/refresh.png";
const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode =
    inputType === "number" ? (
      <InputNumber
        style={{ width: 200 }}
        className="editable-field"
        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
        parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}
      />
    ) : (
      <Input className="editable-field" />
    );
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          // rules={[{ required: true, message: `Please Input ${title}!` }]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

export const RealVolume = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [editingKey, setEditingKey] = useState("");
  const [isSaveClicked, setIsSaveClicked] = useState(false);
  const [cellToggleState, setCellToggleState] = useState({});
  const [modifiedRows, setModifiedRows] = useState(new Set());

  useEffect(() => {
    dispatch(ListingRealVolumeKeysThunk());
    dispatch(ListingRealVolumeValueThunk());
  }, []);

  const {
    ListingRealVolumeKeyData,
    ListingRealVolumeKeyLoading,
    ListingRealVolumeValueData,
    ListingRealVolumeValueLoading,
  } = useSelector((state) => state.ListingRealVolumeReducer);

  const isEditing = (record) => record.key === editingKey;

  const edit = (record) => {
    form.setFieldsValue({ ...record });
    setEditingKey(record.key);
  };

  const cancel = () => {
    setEditingKey("");
  };
  const dataSource = () => {
    const arr = [];
    ListingRealVolumeValueData.forEach((item, index) => {
      const obj = {};
      ListingRealVolumeKeyData.forEach((element) => {
        const key = element.key;
        const manualKey = `${key}Manual`;
        const cellKey = `${item.listingRealVolume.id}_${key}`;
        obj[element.key] = item.listingRealVolume[element.key];
        obj.id = item.listingRealVolume.id;

        const manualDataExists =
          item.listingRealVolume[manualKey] &&
          item.listingRealVolume[manualKey] != 0;
        obj[key] = !manualDataExists
          ? item.listingRealVolume[key] // Automatic data
          : item.listingRealVolume[manualKey]; // Manual data
        obj[`${key}_hasManualData`] = manualDataExists ? true : false;
        obj.id = item.listingRealVolume.id;
      });

      arr.push({ name: item.assetName, ...obj, key: index });
    });
    setData(arr);
    setOriginalData(arr);
    return arr;
  };

  useEffect(() => {
    if (ListingRealVolumeKeyData?.length && ListingRealVolumeValueData.length) {
      dataSource();
    }
  }, [ListingRealVolumeKeyData, ListingRealVolumeValueData, cellToggleState]);

  // Handle toggle for each cell
  const handleToggleCellData = async (rowId, colKey) => {
    const cellKey = `${rowId}_${colKey}`;
    setCellToggleState((prevState) => ({
      ...prevState,
      [cellKey]: !prevState[cellKey],
    }));

    // Prepare data to remove manual entry
    const row = data.find((item) => item.id === rowId);
    if (!row) return;

    const manualKey = `${colKey}Manual`;
    const newItem = { id: row.id, [manualKey]: null }; // Remove manual data

    // Call API to remove manual data
    setLoading(true);
    try {
      await ApiPutListingRealVolumeValue({ volumes: [newItem] });
      setModifiedRows((prev) => {
        const newSet = new Set(prev);
        newSet.delete(cellKey); // Remove the cell from modified rows after save
        return newSet;
      });
      // Fetch updated data from the backend
      dispatch(ListingRealVolumeKeysThunk());
      dispatch(ListingRealVolumeValueThunk());
    } catch (error) {
      console.error("Failed to update manual data:", error);
    } finally {
      setLoading(false);
    }
  };

  const editAssetValue = () => {
    setLoading(true);
    const changedData = data.filter((item, index) => {
      const originalItem = originalData[index];
      return Object.keys(item).some((key) => item[key] !== originalItem[key]);
    });
    // const editData = changedData.map((item) => {
    //   return {
    //     id: item.id,
    //     allTimePeakVolume: parseFloat(item.allTimePeakVolume),
    //     sevenDayAverageManual: item.sevenDayAverage.toString(),
    //     ninetyDayAverageManual: item.ninetyDayAverage.toString(),
    //   };
    // });
    const editData = Array.from(modifiedRows).reduce((acc, cellId) => {
      if (typeof cellId === "string") {
        const [rowId, colKey] = cellId.split("_");
        const row = data.find((item) => item.id === parseInt(rowId, 10));
        if (!row) return acc;

        // Find or initialize an item in acc for this row ID
        let newItem = acc.find((item) => item.id === row.id);
        if (!newItem) {
          newItem = { id: row.id }; // Initialize a new item for this row if not found
          acc.push(newItem);
        }

        // Set only modified fields in newItem
        // const value = row[colKey];
        if (colKey === "sevenDayAverage") {
          newItem[`${colKey}Manual`] = row[colKey] ? String(row[colKey]) : "0";
        } else if (colKey === "ninetyDayAverage") {
          newItem[`${colKey}Manual`] = row[colKey] ? String(row[colKey]) : "0";
        } else if (colKey === "allTimePeakVolume") {
          newItem[`${colKey}`] = parseFloat(row[colKey]);
        } else {
          newItem[`${colKey}`] = String(row[colKey]);
        }
        // newItem[colKey] = value;
        // newItem[manualKey] = value ? Number(value) : 0;
        console.log(newItem, "newItem");
      }

      return acc;
    }, []);

    const payload = { volumes: editData };

    ApiPutListingRealVolumeValue(payload)
      .then(() => {
        setLoading(false);
        dispatch(ListingRealVolumeKeysThunk());
        dispatch(ListingRealVolumeValueThunk());
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const save = async (key) => {
    try {
      const row = await form.validateFields();
      const newData = [...data];
      const index = newData.findIndex((item) => key === item.key);

      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...row });
        setData(newData);
        Object.keys(row).forEach((colKey) => {
          if (row[colKey] !== item[colKey]) {
            // Only if value has changed
            setModifiedRows((prev) =>
              new Set(prev).add(`${item.id}_${colKey}`)
            );
          }
        });
        setEditingKey("");
      } else {
        newData.push(row);
        setData(newData);
        setEditingKey("");
      }
      setIsSaveClicked(true);
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };
  useEffect(() => {
    if (isSaveClicked) dispatch(ListingRealVolumeKeysThunk());
    dispatch(ListingRealVolumeValueThunk());
  }, [isSaveClicked]);

  useEffect(() => {
    if (modifiedRows && isSaveClicked) {
      editAssetValue();
    }
    setIsSaveClicked(false);
  }, [modifiedRows, isSaveClicked]);

  const columns = () => [
    { title: "Asset", dataIndex: "name", fixed: "left" },
    ...ListingRealVolumeKeyData.filter(
      (item) =>
        item.key === "allTimePeakVolume" ||
        item.key === "sevenDayAverage" ||
        item.key === "ninetyDayAverage"
    ).map((item) => ({
      title: (
        <span>
          {item.key !== "allTimePeakVolume" ? "Transactions " : ""}
          {item.name}
        </span>
      ),
      dataIndex: item.key,
      editable: true,
      render: (text, record) => {
        const hasManualData = record[`${item.key}_hasManualData`];
        const cellKey = `${record.id}_${item.key}`;
        return (
          <span>
            <span style={{ color: hasManualData ? "#1890ff" : "#000" }}>
              {text
                ? Number(text)
                    ?.toFixed(0)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                : ""}
            </span>
            {hasManualData && (
              <img
                src={ReloadIcon}
                alt="Reload"
                style={{
                  width: "20px",
                  position: "absolute",
                  top: "15px",
                  right: "10px",
                  transform: "translateY(-50%)",
                }}
                onClick={() => handleToggleCellData(record.id, item.key)}
              />
            )}
          </span>
        );
      },
    })),
    {
      title: "Operation",
      dataIndex: "operation",
      fixed: "right",
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link
              onClick={() => save(record.key)}
              style={{ marginRight: 8 }}
            >
              Save
            </Typography.Link>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel} okText="Yes">
              <a>Cancel</a>
            </Popconfirm>
          </span>
        ) : (
          <Typography.Link
            disabled={editingKey !== ""}
            onClick={() => edit(record)}
          >
            Edit
          </Typography.Link>
        );
      },
    },
  ];

  const mergedColumns = columns().map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: "number",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  return (
    <div>
      {/* <Button className="asset_save" onClick={editAssetValue}>
        SAVE
      </Button> */}

      <Form form={form} component={false}>
        <Table
          loading={
            ListingRealVolumeKeyLoading ||
            ListingRealVolumeValueLoading ||
            loading
          }
          components={{ body: { cell: EditableCell } }}
          pagination={false}
          bordered
          dataSource={data}
          className="custom-scroll"
          columns={mergedColumns}
          rowClassName="editable-row"
          scroll={{
            // x: "80vw", // Horizontal scroll
            y: 500, // Vertical scroll
          }}
        />
      </Form>
    </div>
  );
};
