
import React, { useState, useEffect } from 'react';
import { Form, Input, InputNumber, Popconfirm, Table,Typography} from 'antd';

import { useDispatch, useSelector } from "react-redux";
import { ApiPutListingHoldersConcentrationValue, ApiPutListingMarketCapValue } from '../../Services';
import ReloadIcon from "../../Assets/img/icons/refresh.png"
import { ListingMarketCapKeysThunk, ListingMarketCapValueThunk } from '../../Store/Thunks/ListingMarketCapThunk';
const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  // const inputNode = inputType === 'number' ? <InputNumber   className="editable-field" /> : <Input  className="editable-field" />;
  const inputNode = <Input  className="editable-field" />;
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              // required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};
export const MarketCap = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [editingKey, setEditingKey] = useState('');
  const [isSaveClicked, setIsSaveClicked] = useState(false);
  const [modifiedRows, setModifiedRows] = useState(new Set());
  const [cellToggleState, setCellToggleState] = useState({}); // Track each cell's state individually

  useEffect(() => {
    dispatch(ListingMarketCapKeysThunk());
    dispatch(ListingMarketCapValueThunk());
  }, []);


const {
  ListingMarketCapKeyData,
  ListingMarketCapKeyLoading,
   ListingMarketCapValueData,
  ListingMarketCapValueLoading,
} = useSelector((state) => state.ListingMarketCapReducer);

 const isEditing = (record) => record.key === editingKey;
  const edit = (record) => {
    form.setFieldsValue({
      ...record,
    });
    setEditingKey(record.key);
  };
  const cancel = () => {
    setEditingKey('');
  };

 
const dataSource = () => {
  const arr = [];
  
  ListingMarketCapValueData?.forEach((item, index) => {
    const obj = {};

    ListingMarketCapKeyData?.forEach((element) => {
      const key = element.key;
      const manualKey = `${key}Manual`;
      const cellKey = `${item?.listingMarketCap.id}_${key}`;

      // Check if manual data exists and if specific cell's state allows for manual data
      const manualDataExists = item?.listingMarketCap[manualKey] && Number(item?.listingMarketCap[manualKey]) !== 0 && item?.listingMarketCap[manualKey] !==null ;
      obj[key] = !manualDataExists
        ? item.listingMarketCap[key]  // Automatic data
        : item.listingMarketCap[manualKey]; // Manual data
      

      // Store the hasManualData flag for each cell
      obj[`${key}_hasManualData`] = manualDataExists ? true : false;
    });

    // Add additional fields like id and name
    obj.id = item.listingMarketCap.id;
    obj.name = item.assetName;

    // Push the formatted object to the array
    arr.push({
      ...obj,
      key: index,
    });
  });

  setData(arr);
  return arr;
};

// Update data when dependencies or cell state changes
useEffect(() => {
  if (
    ListingMarketCapKeyData?.length &&
    ListingMarketCapValueData.length
  ) {
    dataSource();
  }
}, [ListingMarketCapKeyData, ListingMarketCapValueData, cellToggleState]);

const editAssetValue = () => {
  setLoading(true);

  const editData = Array.from(modifiedRows).reduce((acc, cellId) => {  
    if (typeof cellId === "string") {
      const [rowId, colKey] = cellId.split('_');
      const row = data.find((item) => item.id === parseInt(rowId, 10));
      if (!row) return acc;

      // Find or initialize an item in acc for this row ID
      let newItem = acc.find((item) => item.id === row.id);
      if (!newItem) {
        newItem = { id: row.id };
        acc.push(newItem);
      }

      // Set only modified fields in newItem
      const value = row[colKey];
      const manualKey = `${colKey}Manual`;

      if (colKey === 'addressesHoldingMore1Percent') {
        newItem[manualKey] = value ? String(value) : "";
      } else if (colKey === 'addressesHolding01To1Percent') {
        newItem[manualKey] = value ? String(value) : "";
      } else if (colKey === 'addressesHoldingLess01Percent') {
        newItem[manualKey] = value ? Number(value) : 0;
      } else if (colKey === 'holdersMore01PercentNetflow7Day') {
        newItem[manualKey] = value ? Number(value) : 0;
      }
      else {
        // Handle other cases if needed
        newItem[manualKey] = value;  // default manual key assignment
      }
    }
    return acc;
  }, []);

  ApiPutListingMarketCapValue(editData)
    .then(() => {
      setLoading(false);
      setModifiedRows(new Set());
    })
    .catch(() => {
      setLoading(false);
    });
};


const save = async (key) => {
  try {
    const row = await form.validateFields();
    const newData = [...data];
    const index = newData.findIndex((item) => key === item.key);

    if (index > -1) {
      const item = newData[index];
      const updatedRow = { ...item, ...row };
      newData.splice(index, 1, updatedRow);
      setData(newData);

      // Track only modified cells
      Object.keys(row).forEach(colKey => {
        if (row[colKey] !== item[colKey]) { // Only if value has changed
          setModifiedRows(prev => new Set(prev).add(`${item.id}_${colKey}`));
        }
      });

      setEditingKey('');
    } else {
      newData.push(row);
      setData(newData);
      setEditingKey('');
    }
    setIsSaveClicked(true);
    // Fetch updated data from the backend
    dispatch(ListingMarketCapKeysThunk());
    dispatch(ListingMarketCapValueThunk());

  } catch (errInfo) {
    console.log('Validate Failed:', errInfo);
  }
};

  useEffect(()=>{
    if(modifiedRows && isSaveClicked){
      editAssetValue();
    }
    setIsSaveClicked(false)
  },[modifiedRows,isSaveClicked])
   

  useEffect(()=>{
    // if(isSaveClicked)
    dispatch(ListingMarketCapKeysThunk());
    dispatch(ListingMarketCapValueThunk());
    // setIsSaveClicked(false); 
  },[isSaveClicked])


  const handleToggleCellData = async (rowId, colKey) => {
    const cellKey = `${rowId}_${colKey}`;
    setCellToggleState((prevState) => ({
      ...prevState,
      [cellKey]: !prevState[cellKey],
    }));
  
    // Prepare data to remove manual entry
    const row = data.find((item) => item.id === rowId);
    if (!row) return;
  
    const manualKey = `${colKey}Manual`;
    const newItem = { id: row.id, [manualKey]: null }; // Remove manual data
  
    // Call API to remove manual data
    setLoading(true);
    try {
      await ApiPutListingMarketCapValue([newItem]);
      setModifiedRows((prev) => {
        const newSet = new Set(prev);
        newSet.delete(cellKey); // Remove the cell from modified rows after save
        return newSet;
      });
       // Fetch updated data from the backend
       dispatch(ListingMarketCapKeysThunk());
       dispatch(ListingMarketCapValueThunk());
  
    } catch (error) {
      console.error("Failed to update manual data:", error);
    } finally {
      setLoading(false);
    }
  };

  const columns = () => {
    return [
      {
        title: 'Asset',
        dataIndex: 'name',
        fixed: 'left',
        width: 100,
      },
      ...ListingMarketCapKeyData.map((item) => ({
        title: item.name,
        dataIndex: item.key,
        editable: true,
        width: 80,
        render: (text, record) => {
          const hasManualData = record[`${item.key}_hasManualData`];
          const cellKey = `${record.id}_${item.key}`;
          const isManualDataDisplayed = cellToggleState[cellKey];     
          return (
            <div style={{ position: 'relative' }}>
              <span style={{ color: (hasManualData && !isManualDataDisplayed) ? "#1890ff" : "#000" }}>
                {/^-|\d+(\.\d+)?$/.test(text)
                  ? text && text !== "0"
                    ? Number(text).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    : text.toString()
                  : text                 
                 }
              </span>
              {(hasManualData) && (
                <img
                  src={ReloadIcon}
                  alt="Reload"
                  style={{
                    width: "20px",
                    position: "absolute",
                    top: "50%",
                    right: "10px",
                    transform: "translateY(-50%)"
                  }}
                  onClick={() => handleToggleCellData(record.id, item.key)} // Toggle specific cell
                />
              )}
            </div>
          );
        }
      })),
      {
        title: 'Operation',
        dataIndex: 'operation',
        fixed: 'right',
        width: 100,
        render: (_, record) => {
          const editable = isEditing(record);
          return editable ? (
            <span>
              <Typography.Link onClick={() => save(record.key)} style={{ marginRight: 8 }}>
                Save
              </Typography.Link>
              <Popconfirm title="Sure to cancel?" onConfirm={cancel} okText="Yes">
                <a>Cancel</a>
              </Popconfirm>
            </span>
          ) : (
            <Typography.Link disabled={editingKey !== ''} onClick={() => edit(record)}>
              Edit
            </Typography.Link>
          );
        },
      },
    ];
  };
  


  const mergedColumns = columns().map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: 'number',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  
  return (
    <div>
      {/* <Button className='asset_save' onClick={editAssetValue}>
        SAVE
      </Button> */}
    <Form form={form} component={false}>
      <Table
      loading={ListingMarketCapKeyLoading || ListingMarketCapValueLoading  || loading}
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        pagination={false}
        bordered
        dataSource={data}
        className="custom-scroll"
        columns={mergedColumns}
        rowClassName="editable-row"
        // scroll={{
        //   x: '80vw', // Horizontal scroll
        //   // y: 500, // Vertical scroll
        // }}
        scroll={{ y: 500 }}
      />
    </Form>
    </div>

  );
};

