import {
  ApiGetListingRegulationKeys,
  ApiGetListingRegulationValue,
} from "../../Services";
import {
  LISTING_REGULATION_KEYS_FAIL,
  LISTING_REGULATION_KEYS_REQUEST,
  LISTING_REGULATION_KEYS_SUCCESS,
  LISTING_REGULATION_VALUE_FAIL,
  LISTING_REGULATION_VALUE_REQUEST,
  LISTING_REGULATION_VALUE_SUCCESS,
} from "../Actions";

export const ListingRegulationKeysThunk = () => {
  return async (dispatch) => {
    dispatch({
      type: LISTING_REGULATION_KEYS_REQUEST,
    });
    try {
      const response = await ApiGetListingRegulationKeys();
      const { data } = response;

      dispatch({
        type: LISTING_REGULATION_KEYS_SUCCESS,
        payload: data?.data,
      });
    } catch ({ response }) {
      const { data } = response;
      dispatch({
        type: LISTING_REGULATION_KEYS_FAIL,
        payload:
          typeof data?.error?.message === "string"
            ? data?.error?.message
            : data?.error?.message[0],
      });
    }
  };
};

export const ListingRegulationValueThunk = () => {
  return async (dispatch) => {
    dispatch({
      type: LISTING_REGULATION_VALUE_REQUEST,
    });
    try {
      const response = await ApiGetListingRegulationValue();
      const { data } = response;

      dispatch({
        type: LISTING_REGULATION_VALUE_SUCCESS,
        payload: data?.data,
      });
    } catch ({ response }) {
      const { data } = response;
      dispatch({
        type: LISTING_REGULATION_VALUE_FAIL,
        payload:
          typeof data?.error?.message === "string"
            ? data?.error?.message
            : data?.error?.message[0],
      });
    }
  };
};
