import {
  createListingAssets,
  deleteListingAssets,
  getListingAssets,
  getListingSingleAsset,
  updateListingAssets,
} from "../../Services";
import {
  LISTING_ASSETS_LIST,
  LISTING_ASSET_ERROR,
  LISTING_CREATING_ASSET,
  LISTING_DELETING_ASSET,
  LISTING_LOADING,
  LISTING_SINGLE_ASSET,
  LISTING_SINGLE_LOADING,
  LISTING_UPDATING_ASSET,
} from "../Reducers/ListingAssetsReducer";

export function GetListingAssetsThunk(param = null) {
  return (dispatch) => {
    getListingAssets(param).then((result) => {
      dispatch({
        type: LISTING_ASSETS_LIST,
        payload: {
          result: result.data.data,
        },
      });
    });
  };
}

export function GetListingSingleAssetThunk(id) {
  return (dispatch) => {
    getListingSingleAsset(id).then((result) => {
      dispatch({
        type: LISTING_SINGLE_ASSET,
        payload: {
          result: result.data.data,
        },
      });
    });
  };
}

export function CreateListingAssetsThunk(data) {
  return (dispatch) => {
    createListingAssets(data)
      .then((e) =>
        dispatch({
          type: LISTING_CREATING_ASSET,
        })
      )
      .catch((e) => {
        return dispatch({
          type: LISTING_ASSET_ERROR,
          payload: {
            errors: e?.response?.data,
          },
        });
      });
  };
}

export function UpdateListingAssetsThunk(data, id) {
  return (dispatch) => {
    updateListingAssets(data, id)
      .then((e) =>
        dispatch({
          type: LISTING_UPDATING_ASSET,
        })
      )
      .catch((e) => {
        return dispatch({
          type: LISTING_ASSET_ERROR,
          payload: {
            errors: e?.response?.data,
          },
        });
      });
  };
}

export function DeleteListingAssetsThunk(id, type = null) {
  return (dispatch) => {
    deleteListingAssets(id, type).then((e) =>
      dispatch({
        type: LISTING_DELETING_ASSET,
      })
    );
  };
}
